const FormConst = Object.freeze({
    ADMIN_ID	                    :  "_admin_id",					// 이지로드 관리자 아이디
    USER_ACCESS_TOKEN				: "_EZ_ACCESS_TOKEN",			// Jwt Access Token 
	USER_REFRESH_TOKEN				: "_EZ_REFRESH_TOKEN",  		// Jwt Refresh Token
    BOOKMARKT_LIST					: '_bookmarkt_list', 			// 즐겨 찾기 메뉴 리스트 
	NAVI_PARAM						: '_navi_param', 				// navigation parameter
	CRUD_MODE						:"_crud_mode",					// CRUD Mode
	RETURN_WND						: '_return_wnd',				// 결과 값을 받을 화면 
    _USER_INFO                      : '_user_info',                 // Session Storage에 등록된 사용자 정보 
    _FROM_URL                       : "_from_url",                  // From URL 
    jqGRID_JSON_PROTOCOL_CURRERNT_PAGE : "page",					//  현재 페이지 번호
    jqGRID_JSON_PROTOCOL_RECORD_AMOUNT : "_grid_record_amount",		// 한 페이지당 레코드 수
    SEARCH_FIELD                    : "_search_field",			    // 검색 필드 구분 
	SEARCH_KEYWORD                  : "_search_keyword",		    // 검색어
	SEARCH_KEYWORD_LIST				: "_search_keyword_list",		// 검색어 리스트
	ORDER_BY_FILED                  : "_order_by_field",		    // 정렬기준 필드 구분
	ORDER_BY_TYPE                   : "_order_by_type_field",	    // 정렬 유형 (오름차순, 내림 차순)
	REG_STATE						: "_reg_state",					// 등록 상태 
    ERROR_MSG                       : "errorMsg",                   // 에러 메세지 
    OBJECT_FIELD                    : "obj",                        // 객체형 결과형 필드
	OBJECT_LIST_FIELD				: "_object_list_field",			// Global Object List Field.
    LIST_FIELD                      : "rows",                       // 배열형 결과값 필드
    MAP_FIELD                       : "map",                        // Map형 결과값 필드 
    EZ_COMPANY_ID                   : "_ez_company_id",			    // 몰 아이디
    USER_ID                         : "_user_id",                   // 사용자 아이읻 
    USER_PW                         : "_user_pw",                   // 사용자 비밀번호  
	SUB_USER_LOGIN_FLAG				: "sub_user_login_flag",		// 부 운영자 로그인 요청 구분
	LOGIN_MODE						: "_login_mode",				// 로그인 모드(로그인 유지, 임시 로그인)
	USER_AUTH_LEVEL					:"_auth_level",					// 권한 레벨 
    USER_COMP_NAME                  : "_user_comp_name",			// 상호
	USER_CEO_NAME		            : "_user_ceo_name",				// 대표자 성명
	USER_COMP_SN		            : "_user_comp_sn",				// 사업자 등록 번호  
	USER_TEL_NO			            : "_user_tel_no",				// 전화번호
	USER_FAX_NO			            : "_user_fax_no",				// 팩스번호
	USER_EMAIL			            : "_user_email",				// 이메일 
    USER_EMAIL_DOMAIN	            : "_user_email_domain",			// 이메일 도에인 
	USER_BIZ_TYPE		            : "_user_biztype",				// 업태
	USER_ITEM_TYPE		            : "_user_itemtype",				// 종목
	USER_ZIPCODE		            : "_user_zipcode",				// 우편번호
	USER_ADDRESS		            : "_user_address",				// 주소
	USER_SUB_ADDRESS	            : "_user_sub_address",			// 상세 주소 
	USER_MANAGER_NAME	            : "_user_manager_name",			// 담당자 성명
	USER_MANAGER_NICK_NAME	        : "_user_manager_nick_name",	// 담당자 닉네임 
	USER_MANAGER_EMAIL		        : "_user_manager_email",		// 담당자 이메일 주소(비밀번호 찾기에서 사용)
	USER_MANAGER_EMAIL_DOMAIN	    : "_user_manager_email_domain",	// 담당자 이메일 주소 도메인 
	USER_MANAGER_HP_NO			    : "_user_manager_hpno",			// 담당자 핸드폰번호 
	USER_COMP_ITEM_CATEGORY		    : "_user_comp_item_category",	// 사업분야(주 취급분야)
	USER_RECEIVE_EMAIL_STATE	    : "_user_receive_email_state",	// 이메일 수신 여부 
	USER_RECEIVE_SMS_STATE		    : "_user_receive_sms_state",	// SMS 수신 여부 
	USER_JOIN_FROM				    : "_user_join_from",			// 가입경로
	USER_JOIN_FROM_DESC			    : "_user_join_from_desc",		// 가입경로 설명
	USER_JOIN_USED_SOLUTION		    : "_user_join_used_sol",		// 타솔루션 여부 
	USER_JOIN_USED_SOLUTION_DESC	: "_user_join_used_sol_desc",	// 타솔루션 이름 
	USER_STAMP_IMAGE				: "_user_stamp_img",			// 도장이미지 
	USER_STAMP_IMAGE_CONTENT_TYPE	: "_user_stamt_img_content_type",		// 도장이미지 데이터 유형
	USER_POLICY_AGE_LIMIT_ACCEPT	: "_user_policy_age_limit_accept",		// 나이 제한 확인 
	USER_POLICY_PRIVATE_INFO_ACCEPT	: "_user_policy_private_info_accept",	// 개인정보 약관 동의 
	USER_POLICY_SERVICE_ACCEPT		: "_user_policy_service_accept",		// 서비스 이용 약관 동의 
	USER_POLICY_MARKETING_ACCEPT	: "_user_policy_marketing_accept",		// 마케팅 이용 약관 동의 
	SEARCH_ADDR_FIELD				: "_search_addr_field",			// 주소 검색 필드
	PAY_INFO_ID						: "pay_info_id",					// 결제 고유 코드
	EZ_WINDOW_ID					: "_ez_window_id",				// 화면 아이디
	
	DOCUMENT_ID						: "_document_id",						// 문서 아이디
	DOCUMENT_DESC					: "_docuement_desc",					// 도움말 비고
	DOCUMENT_IMG_ID 				: "_document_img_id",					// 문서 관련 이미지 고유 아이디
	DOCUMENT_IMG_ID_LIST 			: "_document_img_id_list",				// 문서 관련 이미지 고유 아이디 목록
	DOCUMENT_IMAGE_LIST				: "_document_image_list",				// 문서 관련 이미지 리스트
});


export default FormConst;