const CommonConst = Object.freeze({
    AUTH_LEVEL_GUEST			: 0,					// 권한 레벨 (손님)
	AUTH_LEVEL_NORMAL_USER		: 1,					// 권한 레벨 (일반회원)
	AUTH_LEVEL_SUB_OPERATOR		: 2,					// 권한 레벨 (부운영자)
	AUTH_LEVEL_MAIN_OPERATOR	: 3,					// 권한 레벨 (운영자)
	AUTH_LEVEL_ADMIN			: 9,					// 권한 레벨 (관리자)
	
	AUTH_LEVEL_GUEST_DESC			: "손님허용",					// 권한 레벨 (손님)
	AUTH_LEVEL_NORMAL_USER_DESC		: "일반회원",					// 권한 레벨 (일반회원)
	AUTH_LEVEL_SUB_OPERATOR_DESC	: "부운영자",					// 권한 레벨 (부운영자)
	AUTH_LEVEL_MAIN_OPERATOR_DESC	: "운영자",					// 권한 레벨 (운영자)
	AUTH_LEVEL_ADMIN_DESC			: "관리자",					// 권한 레벨 (관리자)

	OPTION_TRUE						: "true",					
	OPTION_FALSE					: "false",

	CRUD_MODE_INSERT			: "_crud_insert",			// CRUD 등록 모드 
	CRUD_MODE_EDIT				: "_crud_edit",				// CRUD 수정 모드 
	CRUD_MODE_COPY				: "_crud_copy",				// CRUD 복사 모드
	CRUD_MODE_DELETE			: "_crud_delete",			// CRUD 삭제 모드
	CRUD_MODE_MOVE				: "_crud_move",				// CRUD 이동 모드
	
	DATE_FORMAT_STRING			: "YYYYMMDD",				// 날자 포맷
	DATE_TIME_FORMAT_STRING		: "yyyyMMddHHmmss",			// 날자 시간 포맷
	DATE_DISPLAY_FORMAT_STRING		: "YYYY-MM-DD",			// 날자 시간 포맷
	DATE_TIME_DISPLAY_FORMAT_STRING	: "YYYY-MM-DD HH:mm:ss",			// 날자 시간 포맷
	DATE_TIME_EXCEL_FORMAT_STRING	: "yyyy-MM-dd hh:mm:ss",	// 날자 시간 포맷 엑셀.

	DATA_REG_STATE_NORMAL		: "RS01",               // 데이터 등록 상태 (정상)
	DATA_REG_STATE_DELETE		: "RS02",               // 데이터 등록 상태 (삭제)
	DATA_REG_STATE_EDIT			: "RS03",               // 데이터 등록 상태 (수정)

	USER_POLICY_STATE_ACCEPT	: "PS01",				// 사용자 이용 약관 상태(동의)
    USER_POLICY_STATE_DENY		: "PS02",				// 사용자 이용 약관 상태(비 동의)
    LOGIN_MODE_TEMPORARY		: "LM01",				// 로그인 모드(임시 로그인)
	LOGIN_MODE_PERMARNENT		: "LM02",				// 로그인 모드(로그인 유지)

	PRINT_RECORD_TYPE_NORMAL	: "normal",
	PRINT_RECORD_TYPE_BALANCE	: "balance",
	PRINT_RECORD_TYPE_SUMMARY	: "summary",

	MEMBER_SEARCH_FIELD_COMPANY_ID				: "_company_id_field",					// 상점 아이디 
	MEMBER_SEARCH_FIELD_MANAGER_ID				: "_company_manager_id",				// 운영자 아이디
	MEMBER_SEARCH_FIELD_COMPANY_NAME			: "_company_name_field",				// 상점명
	MEMBER_SEARCH_FIELD_USER_NAME				: "_company_manager_name",				// 운영자명 
	MEMBER_SEARCH_FIELD_USER_EMAIL				: "_user_email",						// 이메일
	MEMBER_SEARCH_FIELD_SSN						: "_company_ssn_field",					// 운영자 아이디
	MEMBER_SEARCH_FIELD_TELNO					: "_company_telno_field",				// 운영자 아이디
	MEMBER_SEARCH_FIELD_FAXNO					: "_company_faxno_field",				// 운영자 아이디
	MEMBER_SEARCH_FIELD_LICENCE_END_STATE		: "_company_licence_end_state_field",	// 운영자 아이디
	MEMBER_SEARCH_FIELD_REG_STATE				: "_company_reg_state_field",			// 상점 등록 상태 아이디


	PAY_REG_STATE_WAIT_ACCEPT					: "PR01",									// 이지로드 상품권 등록 상태 (승인대기중)
	PAY_REG_STATE_ACCEPT						: "PR02",									// 이지로드 상품권 등록 상태 (승인완료)
	PAY_REG_STATE_FAIL							: "PR03",									// 이지로드 상품권 등록 상태 (승인실패)
});

export default CommonConst;