import AuthStore from 'store/auth/AuthStore'
import UserStore from 'store/user/UserStore'
import MemberStore from 'store/member/MemberStore'
import OrderStore from 'store/order/OrdeStore'
import DocumentStore from 'store/document/DocumentStore'

export default class RootStore {
    constructor() {
        this.authStore = new AuthStore(this);
        this.userStore = new UserStore(this);
        this.memberStore = new MemberStore(this);
        this.orderStore = new OrderStore(this)
        this.documentStore = new DocumentStore(this);
    }
}