
const SERVER_ROOT = "https://dev.ddimsoft.co.kr:8443/ezroad_v5";
// const SERVER_ROOT = "http://localhost:8080/ezroad_v5";

const URLConst = Object.freeze({
    COOKIE_ROOT                     : SERVER_ROOT,

    ADMIN_LOGIN_REQ_JSON			: SERVER_ROOT + "/admin/login_req_data.json",					// 이지로드 관리자 인증 요청
    ADMIN_INFO_DATA_URL				: SERVER_ROOT + "/admin/manager_info.json",						// 관리자 정보 요청 URL 
    ADMIN_MEMBER_SEARCH_JSON		: SERVER_ROOT + "/admin/member/search_data.json",				// 이지로드 회원 검색 요청
    ADMIN_ORDER_SEARCH_JSON			: SERVER_ROOT + "/admin/order/search_data.json",				// 이지로드 결제 검색 요청
    ADMIN_ORDER_REFUND_JSON			: SERVER_ROOT +"/admin/order/refund_data.json",				// 이지로드 결제 취소 요청
    ADMIN_ORDER_INFO_JSON			: SERVER_ROOT +"/admin/order/order_info_data.json",			// 이지로드 결제 정보 요청

    HELP_DOCUEMNT_INFO_BY_WINDOWID	: SERVER_ROOT +  "/customer/help/document_info_by_windowid",			// 화면 아이디별 사용자 도움말
	DOCUMENT_WINDOW_PATH_DATA		: SERVER_ROOT +  "/customer/document/window_path_data.json",			// 도움말 화면 경로 요청 URL
	DOCUMENT_FUNC_DESC_DATA			: SERVER_ROOT +  "/customer/document/func_desc_data.json",				// 도움말 기능 설명 요청 URL
	DOCUMENT_LIST_DATA				: SERVER_ROOT +  "/customer/documnet/list_data.json",					// 도움말 전체 목록 조회 URL
    DOCUMENT_IMAGE_UPLOAD_DATA		: SERVER_ROOT +  "/customer/document/image_upload_data.json",			// 도움말 관련 이미지 Upload 요청 URL
    DOCUMENT_IMAGE_DOWNLOAD_DATA	: SERVER_ROOT +  "/customer/document/image_down_data.json", 		// 도움말 관련 이미지 Download 요청 URL
    DOCUMENT_FUNC_DESC_UPDATE_DATA	: SERVER_ROOT +  "/customer/document/func_desc_update_data.json",		// 도움말 기능 설명 수정 요청 URL
});

export default URLConst;