/**
 * FlexList에서 
 * 사용될 key property를 
 * 추가하여 반환 합니다. 
 * 
 * 리스트가 null인경우 
 * 빈 객체를 반환 합니다. 
 * 
 * @param {*} lst 
 * @returns 
 */
 export const addKeyProps = (lst, pageNo = 1, pageAmount = 0)=>{
    if(lst != null) {
        let startIdx = (pageNo -1) * pageAmount;
        lst.forEach( (element, i) => {
            element.id = (startIdx + i) + "";
        });
    }
    else {
        lst ={};
    }
    return lst;
};

/**
 * lst 에서 특정 obj를 
 * callback_func 조건에 해당하는 
 * idx에 대체 합니다. 
 * @param {*} lst 
 * @param {*} obj 
 * @param {*} callback_func 
 * @returns 
 */
export const replaceObj = (lst, obj, callback_func) => {
    lst.forEach((element, idx) => {
        if(callback_func(element, obj)) {
            obj.key = idx + "";
            lst.splice(idx, 1, obj);
        }
    });
    return lst;
}

/**
 * 리스트에서 특정 객체를 
 * 삭제하여 반환 합니다. 
 * @param {*} lst 
 * @param {*} obj 
 * @param {*} callback_func 
 * @returns 
 */
export const removeObj = (lst, obj, callback_func) => {
    lst.forEach((element, idx) => {
        if(callback_func(element, obj)) {
            obj.key = idx + "";
            lst.splice(idx, 1);
        }
    });
    return lst;
}

/**
 * 리스트에 특정 객체를 
 * 첫번째 index에 추가하여 
 * 반환 합니다. 
 * @param {*} lst 
 * @param {*} obj 
 * @returns 
 */
export const insertFirstIdxObj = (lst, obj) => {
    if (lst.length === 0) {
        lst = [];
        lst.push(obj);
    }
    else {
        lst.unshift(obj);
    }
    return lst;
}



/**
 * 페이지 번호에 따라 
 * 기존의 목록과 
 * 신규 검색 결과를 병합/대체 합니다. 
 * @param {*} oldList 
 * @param {*} newList 
 * @param {*} pageNo 
 */
export const mergeList = (oldList, newList, pageNo) => {
    let result = [];
    if (pageNo === 1)
        result = newList;
    else {
        result = [...oldList, ...newList];
    }
    return result;
}

/**
 * 
 * @param {*} oldlist 
 * @param {*} newList 
 * @param {*} pageNo 
 * @param {*} setInitialStateCallBack 
 * @param {*} setLastPageCallBack 
 */
export const replaceOrMergeList= (
    oldList, 
    newList, 
    pageNo, 
    pageAmount, 
    searchConditionLength, 
    setInitialStateCallBack, 
    setNoResultCallBack, 
    setLastPageCallBack
) => {
    let result = [];
    // 조회 결과가 없는 경우 입니다. 
    if(newList.length === 0) {
        // 첫페이지 조회인 경우 
        // 빈 목록을 반환 합니다. 
        if(pageNo <= 1 ) {
            // 검색 조건이 없는 경우 데이타 미등록 상태로 간주 합니다. 
            if(searchConditionLength === 0) {
                if(setInitialStateCallBack) setInitialStateCallBack(true);
                if(setLastPageCallBack ) setLastPageCallBack(true);
            }
            else {
                // 검색 결과가 없는 경우로 간주 합니다. 
                if(setNoResultCallBack) setNoResultCallBack(true);
                if(setLastPageCallBack )setLastPageCallBack(true);
            }
            result = [];
        }
        else {
            // 마지막 페이지 입니다. 
            // 이전 목록을 유지 합니다. 
            if(setInitialStateCallBack) setInitialStateCallBack(false);
            if(setNoResultCallBack) setNoResultCallBack(false);
            if(setLastPageCallBack) setLastPageCallBack(true);
            result = oldList;
        }
    }
    else {
        if(setInitialStateCallBack) setInitialStateCallBack(false);
        if(setNoResultCallBack) setNoResultCallBack(false);
        
        // 신규 목록의 개수가 pageAmount보다 
        // 적은 경우 마지막 페이지로 간주 합니다.
        if(setLastPageCallBack) {
            if(newList.length < pageAmount)
             setLastPageCallBack(true);
        else 
             setLastPageCallBack(false);
        }
        // 신규 목록의 virtual Key를 추가 합니다. 
        newList = addKeyProps(newList, pageNo, pageAmount);
        
        if(pageNo <= 1 ) {
            result = newList;
        }
        else {
            // 이전 목록과 신규 목록을 병합 합니다. 
            if(oldList === undefined) {
                oldList = [];
            }
            result = mergeList(oldList, newList, pageNo);
        }
    }

    return result;
}