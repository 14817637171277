import moment from 'moment'

/**
 * 문자열을 Date 객체로 반환 합니다. 
 * @param str : 날짜 문자열 (20120201)
 * @returns	 Date Object
 */
export const stringToDate = (str) => {
	if(str == undefined || str == null) return "";
	let result = new Date();
	
	if(str.length == 8) {
		let yearValue = str.substring(0,4) * 1;
		let monthVale = str.substring(4,6) * 1;
		let dayValue  = str.substring(6)   * 1;
		result.setFullYear(yearValue, monthVale-1, dayValue);
	}
	else if(str.length == 6) {
		let yearValue = str.substring(0,4) * 1;
		let monthVale = str.substring(4,6) * 1;
		let dayValue  = 1;
		result.setFullYear(yearValue, monthVale-1, dayValue);
	}
	return result;
}

/**
 * 특정 날자가 속한 달의 마지막 날자를 구합니다. 
 * 
 * @param dateObj
 * @param del
 * @returns
 */
export const getLastDayOfDate = (dateObj, del) => {
	let lastDate =  new Date(dateObj.getFullYear(), dateObj.getMonth()+1, 0);
	return getDateString(lastDate, del)
}
/**
 * 현재 일자 기준으로 
 * amount 만큼 이전/이후 날짜를 반환 합니다. 
 * @param dateObj 	: 시간 객체  
 * @param amont 	: -1 인경우 한달 이전, 1인경우 한달 후 
 */
export const  getBeforeMonth = (dateObj, months) => {
	let result = new Date(dateObj),
	expectedMonth = ((dateObj.getMonth() + months) % 12 + 12) % 12;
	result.setMonth(result.getMonth() + months);
	if (result.getMonth() !== expectedMonth) {
	  result.setDate(0);
	}
	return result;
}

/**
 * 현재 일자 기준으로 
 * amount 만큼 이전/이후 날짜를 반환 합니다. 
 * @param dateObj 	: null인 경우 현재 시간으로 간주 
 * @param days 		: -1 인경우 하루 이전, 1인경우 하루 이후  
 */
 export const getBeforeDay = (dateObj, days) => {
	let result = new Date();
	if(dateObj != null)
		result = dateObj;
	
	result.setDate(result.getDate() + days);
	
	return result;
}

/**
 * jqGrid Date Formatter for Unix Epoch date (example : 1559001838000)
 */
 export const jqgridUnixEpochDateFomatter = (cellvalue, del="-", subDel=":") => {
	var result = "";
	if(cellvalue != null && cellvalue !="")
		result = getDateTimeString(new Date(cellvalue), del, subDel);
	return result;
}

/**
 * Date Object를 문자열로 변환 합니다. 
 * @param dateObj
 * @param del
 * @return
 */
export const getDateTimeString = (dateObj, del, subDel) => {
	let result = "";
	let monthStr = getMonthString(dateObj);
	let dayStr = getDayString(dateObj);
	result = "" + dateObj.getFullYear() + del + monthStr + del + dayStr + " " + 
		   dateObj.getHours() + subDel +
		   dateObj.getMinutes() + subDel +
		   dateObj.getSeconds();
	return result;
}


/**
 * Date Object를 문자열로 변환 합니다. 
 * @param dateObj
 * @param del
 * @return
 */
export const  getDateString = (dateObj, del)=>{
	let result = "";
	let monthStr = getMonthString(dateObj);
	let dayStr = getDayString(dateObj);
	result = "" + dateObj.getFullYear() + del + monthStr + del + dayStr;
	return result;
}


/**
 * 작업 년월을 반환 합니다. 
 * @param dateObj
 * @param del
 * @returns
 */
export const getWorkMonth = (dateObj, del) => {
	return dateObj.getFullYear() + del + getMonthString(dateObj);
}

export const getMonthString = (dateObj) => {
	let monthStr = dateObj.getMonth() + 1;
	if( monthStr < 10) 
		monthStr = "0" + monthStr;
	else
		monthStr = "" + monthStr;
	return monthStr;
}

export const getDayString = (dateObj) => {
	let dayStr = dateObj.getDate();
	if( dayStr < 10) 
		dayStr = "0" + dayStr;
	else
		dayStr = "" + dayStr;
	return dayStr;
}

/**
 * 날자 문자열을 delemeter 단위로 구분 합니다. 
 * @param str
 * @param del
 * @param subdel
 * @returns {String}
 */
 export const  dateFormatting = (str, del="-", subdel=":")=>{
	let result = "";
	
	if(str != "" && str != null) {
		if (str.length == 14) {
			if(subdel == undefined) subdel = ":";
			
			result = str.substring(0,4) + del + str.substring(4,6) + del + str.substring(6,8) + ' ' +
					str.substring(8,10) + subdel +  
					str.substring(10,12) + subdel + 
					str.substring(12);
		}
		else if(str.length == 8) {
			result = str.substring(0,4) + del + str.substring(4,6) + del + str.substring(6);
		}
	}
	else {
		result = str;
	}
	return result;
}


/**
 * 날자 문자열을 ISO 형식으로 변환 합니다.
 * param : dateStr - 20121122 + 121133
 * return : 20121122T121133
 */
 export const  convertToISO_date = (dateStr) => {
    let result = dateStr;
    result = result.substring(0,8) + "T" + result.substring(8,14);
    return result;
}


/**
 * 초를 시분초로 환산하여 반환 합니다. 
 * @param myNum
 * @returns
 */
 export const secToHHMMSS = (myNum) => {
    let hours   = Math.floor(myNum / 3600);
    let minutes = Math.floor((myNum - (hours * 3600)) / 60);
    let seconds = myNum - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}


/**
 * 년월 문자열이 
 * 이전 년월 시간인지 
 * 여부를 반환 합니다. 
 * @param {*} preMonth 
 * @param {*} postMonth 
 * @returns 
 */
export const isBeforeMonth = (preMonth, postMonth) => {
	let preDate = moment(preMonth);
	let postDate = moment(postMonth);

	return preDate.isBefore(postDate);
}

/**
 * 해당월의 마지막 일자를 반환 합니다. 
 * @param {*} monthStr 
 * @returns 
 */
export const getLastDayOfMonth = (monthStr, formatStr) => {
	let mon = moment(monthStr);
	
	return mon.endOf('month').format(formatStr);
}

/**
 * 해당원의 첫번째 
 * 일자를 반환 합니다. 
 * @param {*} monthStr 
 * @param {*} formatStr 
 * @returns 
 */
export const getFirstDayOfMonth = (monthStr, formatStr) => {
	let mon = moment(monthStr);
	
	return mon.startOf('month').format(formatStr);
}

/**
 * workMonth와 
 * 현재 날자를 비교하여 
 * 작업일자를 반환 합니다. 
 * 
 * 1. workMoth가 현재 날자 이전인 경우 
 *  -> 해당월의 마지막 날자 반환 
 * 2. workMonth가 현재 날자와 같은 경우 
 *  -> 현재 날자를 반환 
 * 3. workMonth가 현자 날자 이후인 경우 
 * -> 해당월의 첫번재 날자 반환 
 * @param {*} workMonthStr
 */
export const getWorkDate = (workMonthStr, formatStr) => {
	let workMonth = moment(workMonthStr);
	let curMonth = moment();
	let curMonthStr = getWorkMonth(new Date(), "");
	
	let workDate = "";
	if(curMonthStr == workMonthStr) {
		workDate = curMonth.format(formatStr);
	}
	else {
		// 현재 시간보다 이전 작업 년월이 주어진 경우 
		// 해당 월의 마지막 일자로 반환 합니다. 
		if(isBeforeMonth(workDate, curMonth)) 
			workDate = getLastDayOfMonth(workMonth, formatStr);
		else 
			workDate = getFirstDayOfMonth(workMonth, formatStr);

	}
	return workDate;
}