import {StatusCodes} from 'http-status-codes'
import EzAppConst from 'constants/EzAppConst';
import EzException from 'exception/EzException'

export const doPostFormData = async (url, paramMap) => {
    try {
        var formBody = [];
        for (var property in paramMap) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(paramMap[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
console.log("--------------------------");        
console.log(url);
// console.log(formBody);
console.log("--------------------------");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            credentials: 'include',         // added for cors cookie.
            body: formBody
        };
        const response = await fetch(url, requestOptions);   
        const data = await response.json();
        if(response.ok) {
            data[EzAppConst.RESPONSE_CODE] = StatusCodes.OK;
            return data;
        }
        
        // 에러 객체를 생성 합니다. 
        const status = data.status;
        let result = {};
        switch(status) {
            case 401 : 
                result = new EzException("접속이 종료 되었습니다",StatusCodes.UNAUTHORIZED );
                throw result;
                break;
            case 403 : 
                result = new EzException("비정상적인 접속 시도 입니다",StatusCodes.FORBIDDEN );
                throw result;
            default : 
                result = new EzException(data?.error, status);
                throw result;
        }
        
    }
    catch(e) {
        // error code가 정의된 
        // 에러를 받은 경우 
        // callback function으로 
        // exception을 전달 합니다. 
        if(e?.error_code != undefined) {
            throw e;
        }

        // Network 에러 등의 경우 
        // error_code값이 할달 되어 
        // 오지 않기 때문에 
        // 에러 코드를 설정 하여 
        // exception을 전달 합니다. 
        let result = new EzException(e.message, StatusCodes.SERVICE_UNAVAILABLE);
        switch(e.message) {
            case 'Network request failed' : 
                let msg = "서버스 점검 중입니다 잠시 후 시도해 주세요\n";
                msg = msg;
                result = new EzException(msg, StatusCodes.SERVICE_UNAVAILABLE);
                break;
        }
        throw result;
    }
}