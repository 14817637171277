import React, { Component } from 'react'

import 'react-quill/dist/quill.bubble.css'
import ReactQuill, { Quill } from 'react-quill'

import { ImageResize } from './imageResize'
import { ImageImport } from './imageImport'
import 'react-quill/dist/quill.snow.css';
// import './editor.css'

import { Video } from './quill-video-resize'
import './quill-video-resize.css'

import * as ImageUtils from 'utils/ImageUtils'

// Add fonts to whitelist
var Font = Quill.import('formats/font')
// We do not add Sans Serif since it is the default
Font.whitelist = ['sans-serif', 'inconsolata', 'roboto', 'mirza', 'sofia']


Quill.register('modules/imageImport', ImageImport)
Quill.register('modules/imageResize', ImageResize)
Quill.register({ 'formats/video': Video })
Quill.register(Font, true)


class Editor extends Component {
    constructor(props) {
        super(props)
        this.quill = null;      // Quill instance
        this.reactQuill = null; // ReactQuill component
        this.state = {
            value: props?.value != null ? props.value : "",
            deltas: {}
        }

    }

    componentDidMount() {
        this.attachQuillRefs(true)
    }

    componentDidUpdate(prevProps, prevState) {
        this.attachQuillRefs()
        if (this.props.value !== prevProps.value) {
            this.setState({
                ...this.state,
                value: this.props.value,
            })
        }
    }

    /**
     * Quill 관련 이벤트를 
     * 설정 합니다. 
     * @returns 
     */
    attachQuillRefs = () => {
        if (typeof this.reactQuill.getEditor !== 'function') return;
        this.quill = this.reactQuill.getEditor();
        // respond to clicks inside the editor
        this.quill.root.addEventListener('click', this.handleClick, false)
        this.quill.root.quill = this.quill;
        var toolbar = this.quill.getModule('toolbar');
        toolbar.addHandler('image', this.imgHandler);
    }

    /**
     * 이미지 선택 
     * 이벤트를 정의 합니다. 
     */
    imgHandler = async () => {
        // file input 임의 생성
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files;
            const formData = new FormData();
            try {
                if (file) {
                    formData.append("multipartFiles", file[0]);
                }
                let base64Str = await ImageUtils.resizeFile(file[0]);
                let imgURL = await this.props.imgRegCallBack(base64Str);        
                // 현재 Editor 커서 위치에 서버로부터 전달받은 이미지 불러오는 url을 이용하여 이미지 태그 추가
                const index = (this.quill.getSelection()).index;
                this.quill.setSelection(index, 1);
                this.quill.clipboard.dangerouslyPasteHTML(
                    index,
                    `<img src=${imgURL} alt=${'alt text'} />`
                );
            }
            catch(e){
                console.log("[Document] Error" , e);
            }
        }
    }

    /**
     * 내용이 변경된 이벤트를 
     * 처리 합니다. 
     * @param {*} value 
     */
    handleTextChange = (value) => {
        this.setState({
            value
        });
        // 부모 객체에 
        // 변경된 내용을 전달 합니다. 
        if(this.props?.onValueChangeCallBack)
            this.props.onValueChangeCallBack(value);
    }

    render() {
        return (
            <div className="editor">
                <ReactQuill
                    theme="snow"
                    ref={(el) => { this.reactQuill = el }}
                    modules={Editor.modules}
                    formats={Editor.formats}
                    placeHolder={"Add anything here..."}
                    value={this.state.value}
                    onChange={this.handleTextChange}
                    readOnly={this.state.readOnly}
                />
            </div>

        )
    }
}

Editor.modules = {
    imageImport: true,
    imageResize: {
        displaySize: true
    },
    toolbar: {
        // container에 등록되는 순서대로 tool 배치
        container: [
            [{ 'font': [] }], // font 설정
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // header 설정
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'formula'], // 굵기, 기울기, 밑줄 등 부가 tool 설정
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], // 리스트, 인덴트 설정
            ['link', 'image', 'video'], // 링크, 이미지, 비디오 업로드 설정
            [{ 'align': [] }, { 'color': [] }, { 'background': [] }], // 정렬, 글씨 색깔, 글씨 배경색 설정
            ['clean'], // toolbar 설정 초기화 설정
        ],

        // custom 핸들러 설정
        // handlers: {
        //     // image: this.props.imageHandler, // 이미지 tool 사용에 대한 핸들러 설정
        // }
    },
    //   toolbar: [
    //     ['bold', 'italic', 'underline', 'strike'],       // toggled buttons
    //     ['blockquote', 'code-block'],                    // blocks
    //     [{ 'header': 1 }, { 'header': 2 }],              // custom button values
    //     [{ 'list': 'ordered'}, { 'list': 'bullet' }],    // lists
    //     [{ 'script': 'sub'}, { 'script': 'super' }],     // superscript/subscript
    //     [{ 'indent': '-1'}, { 'indent': '+1' }],         // outdent/indent
    //     [{ 'direction': 'rtl' }],                        // text direction
    //     [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
    //     [{ 'header': [1, 2, 3, 4, 5, 6, false] }],       // header dropdown
    //     [{ 'color': ['white', 'red', 'green', 'yellow','blue', '#3293ca', '#575452'] },
    //      { 'background': ['white', 'red', 'green', 'yellow','blue', '#3293ca','#575452'] }], // dropdown with defaults
    //     [{ 'font': ['sans-serif', 'inconsolata', 'roboto', 'mirza', 'sofia'] }],                                // font family
    //     [{ 'align': [] }],                               // text align
    //     // ['image', 'video', 'svideo', 'mvideo'],
    //     ['image', 'video' ],
    //     ['clean'],                                       // remove formatting
    //   ],
}

// Editor.formats = [
//   'header',
//   'bold', 'italic', 'underline', 'strike', 'blockquote',
//   'background',
//   'list', 'bullet', 'indent', 'align',
//   'size', 'color', 'font',
//   'link', 'image', 'video', 'width', 'height'
// ]

Editor.formats = [
    'font',
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'formula',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
    'align', 'color', 'background',
]

export default Editor