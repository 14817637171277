import Resizer from "react-image-file-resizer";

/**
 * 이미지를 리사이징하여 
 * Base64 문자열로 반환 합니다. 
 * 참조 
 * https://github.com/onurzorluer/react-image-file-resizer#readme
 * 
 * @param {*} file               Is the file of the image which will resized.
 * @param {*} maxWidth           Is the maxWidth of the resized new image.
 * @param {*} maxHeight          Is the maxHeight of the resized new image.
 * @param {*} compressFormat     Is the compressFormat of the resized new image.
 * @param {*} quality            Is the quality of the resized new image.
 * @param {*} rotation           Is the degree of clockwise rotation to apply to uploaded image.
 * @param {*} responseUriFunc    Is the callBack function of the resized new image URI.
 * @param {*} outputType         Is the output type of the resized new image.
 * @param {*} minWidth           Is the minWidth of the resized new image.
 * @param {*} minHeight          Is the minHeight of the resized new image.
 * @returns 
 */
export const resizeFile = (
    file, 
    maxWidth = 800, 
    maxHeight = 600, 
    compressFormat = "PNG", 
    quality = 100, 
    rotation = 0,  
    responseUriFunc,  
    outputType = "base64", 
    minWidth, 
    minHeight  
) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            maxWidth,
            maxHeight,
            compressFormat,
            quality,
            rotation,
            (uri) => {
                resolve(uri);
            },
            outputType,
        );
    });

