const EzAppConst = Object.freeze({
    NEW_RECORD_FLAG     : "isNewItem",              // 신규 등록 레코드 구분자 

    RESPONSE_CODE       : "_response_code",         // 서버 응답 코드 

    SELECTED_PARTNER    : "_selected_partner",      // 선택된 거래처 객체 
    SELECTED_ITEM       : "_selected_item",         // 선택된 상품 객체 

    WORK_MONTH_FORMAT   : "YYYYMM",                 // 작업년월 문자 형식 
    BILL_BALANCE_OBJ    : "balanceObj",             // 관련 입출금 객체  
    BILL_ITEM_LIST      : "_bill_item_list",        // 전표의 상품 목록 

    BILL_TOTAL_SUPPLY   : "_bill_total_supply",     // 총 공급가 
    BILL_TOTAL_TAX      : "_bill_total_tax",        // 총 세액 
    BILL_TOTAL_AMOUNT   : "_bill_total_amount",     // 총 거래액 
    BILL_TOTAL_DC       : "_bill_total_dc",         // 총 할인액 
    BILL_TOTAL_BALANCE  : "_bill_total_balance",    // 총 입출금액 
    BILL_FINAL_BANALCE  : "_bill_final_balance",    // 최종 미불/미수 잔액 
}); 

export default EzAppConst;