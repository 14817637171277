
import React, { useEffect } from 'react'
import { observer } from "mobx-react-lite"
import { useUserStore } from 'store/RootProvider'
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './menu/listItems';

import Drawer from 'pages/menu/Drawer';
import MemberList from 'pages/member/MemberList';
import MemberDetail from 'pages/member/MemberDetail';

import Deposits from '../Deposits';
import OrderList from 'pages/order/OrderList';
import OrderDetail from 'pages/order/OrderDetail';
import DocumentList from 'pages/document/DocumentList'
import Dashboard from './main/DashBoard';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const mdTheme = createTheme();

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const DashboardContent = ({
    userInfo,
}) => {
    const [open, setOpen] = React.useState(true);
    
    useEffect( ()=>{
        // Drawer Menu open 
        // 상태를 모바일 여부에 따라 설정 합니다. 
        setOpen(!isMobile);
        
    }, []);
    
    
    const toggleDrawer = () => {
        setOpen(!open);
    };



    return (
        <BrowserRouter>
            <ThemeProvider theme={mdTheme}>
                <Box sx={{ display: 'flex' }}>
                    <AppBar position="absolute" open={open}>
                        <Toolbar
                            sx={{
                                pr: '24px', // keep right padding when drawer closed
                            }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    ...(open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                Dashboard
                            </Typography>
                            <IconButton color="inherit">
                                <Typography>
                                    안녕하세요 [{userInfo?.ezManagerName}]님
                                </Typography>
                            </IconButton>
                            <IconButton color="inherit">
                                <Badge badgeContent={2} color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Drawer variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        <List component="nav">
                            {mainListItems}
                            <Divider sx={{ my: 1 }} />
                            {secondaryListItems}
                        </List>
                    </Drawer>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Container 
                            maxWidth={false}
                            
                            sx={{ 
                                mt: 10, 
                                mb: 8 ,
                                // backgroundColor:'red',
                            }}>
                            {/** Menu Navigation 정의 */}
                            <Routes>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/members" element={<MemberList />} />
                                <Route path="/members/detail" element={<MemberDetail />} />
                                <Route path="/dash" element={<Deposits />} />
                                <Route path="/orders" element={<OrderList />} />
                                <Route path="/orders/detail" element={<OrderDetail />} />
                                <Route path="/documents" element={<DocumentList />} />
                            </Routes>
                            {/* <Copyright sx={{ pt: 4 }} /> */}
                        </Container>
                    </Box>
                </Box>
            </ThemeProvider>
        </BrowserRouter>
    );
}

const MainNavagation = observer(() => {
    const userStore = useUserStore();
    return <DashboardContent
        userInfo={userStore.userInfo}
    />;
});

export default MainNavagation;
