
import React, { useEffect } from 'react'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';


import { observer } from "mobx-react-lite"
import { useUserStore } from 'store/RootProvider'

const mdTheme = createTheme();

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const DashboardContent = ({
	userInfo,
}) => {
	const [open, setOpen] = React.useState(true);
	const toggleDrawer = () => {
		setOpen(!open);
	};

	return (
		<ThemeProvider theme={mdTheme}>
			<Box
				component="main"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === 'light'
							? theme.palette.grey[100]
							: theme.palette.grey[900],
					flexGrow: 1,
					height: '100vh',
					overflow: 'auto',
					display: 'flex',
				}}
			>
				<Toolbar />
				<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
					데쉬 보드
				</Container>
			</Box>
		</ThemeProvider>
	);
}

const Dashboard = observer(() => {
	const userStore = useUserStore();
	return <DashboardContent
		userInfo={userStore.userInfo}
	/>;
});

export default Dashboard;
