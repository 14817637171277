import React, { createContext, useContext } from "react";
import RootStore from 'store/RootStore'

const rootContext = createContext();

export const RootProvider = ({children}) => {
    let store = new RootStore();
    
    return (
        <rootContext.Provider value={store}>
            {children}
        </rootContext.Provider>
    );
}

// create the hook
export const useRootStore = () => {
    const context = useContext(rootContext)
    if (context === undefined) {
        throw new Error("useRootStore must be used within RootStoreProvider")
    }
    return context
}

export const useAuthStore = () => {
    const { authStore } = useRootStore();
    return authStore;
}

export const useUserStore = () => {
    const { userStore } = useRootStore();
    return userStore;
}

export const useMemberStore = () => {
    const { memberStore } = useRootStore();
    return memberStore;
}


export const useOrderStore = () => {
    const { orderStore } = useRootStore();
    return orderStore;
}


export const useDocumentStore = () => {
    const { documentStore } = useRootStore();
    return documentStore;
}